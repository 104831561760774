<template>
  <div class="dbox">
    <!-- <div class="left">
     <el-container>
       <CommonAside />
     </el-container>
   </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="kf_box" v-for="(item, index) in barNameList" :key="index" @click="ontabtap(index)">
        <img src="../../assets/kefu.09877e8b.png" style="width: 40px; height: 40px;" alt="">
        <div class="kf_iuy">
          <div class="fx_tz">{{ item.title }}</div>
          <div>Los motores en .Game Framework</div>
        </div>
      </div>
      <tabBar />
    </div>
  </div>
</template>
 
<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue';
import { customerService } from '../../api/index.js'
export default {
  data() {
    return {
      barNameList: [],
    };
  },
  methods: {
    ontabtap(index) {
      window.location.href = this.barNameList[index].url
    },
    Logout() {
      customerService().then((res) => {
        if (res) {
          this.barNameList = res.data
        }
        
      })
    }
  },
  components: {
    //  CommonAside,
    //  TopRes,
    tabBar
  },
  mounted() {
    this.Logout()
  },
}
</script>
 
<style scoped>
.kf_box {
  width: 40%;
  height: 90px;
  margin: 60px auto 0 auto;
  background-color: rgba(255, 255, 255, .1);
  margin-top: 70px;
  display: flex;
  border-radius: 15px;
}

.kf_iuy {
  color: #fff;
}

.kf_box img {
  margin-left: 40px;
  margin-top: 25px;
}

.kf_box div {
  margin-left: 40px;
  margin-top: 10px;
}

.fx_tz {
  margin-top: 25px;
}</style>
 